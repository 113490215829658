import i18n, {Resource, i18n as i18nType, ResourceLanguage} from 'i18next';
import * as i18nReact from 'react-i18next';

import 'dayjs/locale/de';
import 'dayjs/locale/en';
import 'dayjs/locale/fr';
import 'dayjs/locale/it';

import dayjs from 'dayjs';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';

import LanguageDetector from 'i18next-browser-languagedetector';
import {portalExtensionLanguageDetector} from './portalExtensionLanguageDetector';

dayjs.extend(LocalizedFormat);

export interface AdditionalLanguageResource {
    language: string;
    namespace: string;
    resourceFile: ResourceLanguage;
}

const languageDetector = new LanguageDetector();
languageDetector.addDetector(portalExtensionLanguageDetector);

export const i18nInit = async (resources: Resource): Promise<i18nType> => {
    await i18n
        .use(languageDetector)
        .use(i18nReact.initReactI18next)
        .init({
            debug: false,
            defaultNS: 'common',
            detection: {
                order: ['portalExtension', 'navigator']
            },
            fallbackLng: 'en',
            interpolation: {
                escapeValue: false // not needed for react as it escapes by default
            },
            resources: resources
        });
    return i18n;
};
i18n.on('languageChanged', (lng) => dayjs.locale(lng.substring(0, 2)));

export function addAdditionalLanguageResources(additionalResources: Array<AdditionalLanguageResource>): void {
    additionalResources.forEach((resource) => {
        i18n.addResourceBundle(resource.language, resource.namespace, resource.resourceFile);
    });
}

export {i18n, i18nReact, dayjs};
