const system = {
    sizing: new Set(['minWidth', 'maxWidth', 'maxHeight', 'minHeight', 'sizeWidth', 'sizeHeight', 'boxSizing']),
    spacing: new Set([
        'm',
        'mt',
        'mr',
        'mb',
        'ml',
        'mx',
        'my',
        'p',
        'pt',
        'pr',
        'pb',
        'pl',
        'px',
        'py',
        'margin',
        'marginTop',
        'marginRight',
        'marginBottom',
        'marginLeft',
        'marginX',
        'marginY',
        'padding',
        'paddingTop',
        'paddingRight',
        'paddingBottom',
        'paddingLeft',
        'paddingX',
        'paddingY'
    ])
};
type SystemKey = keyof typeof system;

export const filter = (key: SystemKey): any => ({
    shouldForwardProp: (prop: never) => !system[key].has(prop)
});
