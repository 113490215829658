import {useMemo} from 'react';

export enum ImageSize {
    THUMBNAIL = 'thumbnail',
    SMALL = 'small',
    MEDIUM = 'medium',
    LARGE = 'large'
}

export const useImageUrl = (templateUrl?: string, size: ImageSize = ImageSize.MEDIUM): string | undefined =>
    // eslint-disable-next-line no-template-curly-in-string
    useMemo(() => templateUrl?.replace('${size}', size), [templateUrl, size]);
