import {NavigationBar, NavigationBarActionsList, SegmentedTitle} from './interfaces/navigationBar';

export type SearchFunction = (searchTerm: string) => void;
export type NavigationFunction = (navTo: string) => void;

export interface HeaderBarOptions {
    icon: string;
    defaultTitle: string;
    urlPath: string;
    title?: string;
    segmentedTitle?: SegmentedTitle;
}

export type HeaderBar = NavigationBar;

export type HeaderBarActions = NavigationBarActionsList;

export const buildHeaderBar = (
    options: HeaderBarOptions,
    mainFunction: VoidFunction,
    actions: HeaderBarActions = {},
    searchFunction?: SearchFunction
): HeaderBar => {
    const {icon, title, segmentedTitle, defaultTitle, urlPath} = options;
    return {
        icon,
        defaultTitle,
        title,
        segmentedTitle,
        urlPath,
        mainFunction,
        actions,
        search: searchFunction !== undefined ? true : false,
        searchFunction
    } as HeaderBar;
};
