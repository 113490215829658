import {ZoidProps} from './interfaces/zoidProps';

export const zoidProps: ZoidProps = {
    getAuthToken: {
        type: 'function'
    },
    onTokenChange: {
        type: 'function'
    },
    getCurrentGroupId: {
        type: 'function'
    },
    onGroupIdChange: {
        type: 'function'
    },
    setNavigationBar: {
        type: 'function'
    },
    sendMessage: {
        type: 'function'
    },
    showProgressBar: {
        type: 'function'
    },
    getCurrentLanguage: {
        type: 'string'
    },
    onLanguageChange: {
        type: 'function'
    },
    getCurrentUnit: {
        type: 'string'
    },
    onUnitChange: {
        type: 'function'
    },
    getCurrentPath: {
        type: 'string'
    },
    onPathChange: {
        type: 'function'
    }
};

export default zoidProps;
