import {useEffect, useMemo} from 'react';
import {HeaderBar} from '../headerBarHelper';
import {PortalHelper} from '../portalHelper';

export const useHeaderBar = (
    headerBar: HeaderBar,
    title = headerBar.title,
    segmentedTitle = headerBar.segmentedTitle
): void => {
    const bar = useMemo(() => ({...headerBar, title, segmentedTitle}), [headerBar, title, segmentedTitle]);
    useEffect(() => {
        PortalHelper.updateNavigationBar(bar);
    }, [bar]);
};
